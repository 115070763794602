import React, { createContext, useState, useCallback, useEffect } from 'react';

export const SessionContext = createContext();

export function SessionProvider({ children }) {
  const [storedUser, setStoredUser] = useState(null);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('user'));

    if (session) {
      setStoredUser(session);
    }
  }, []);

  const updateSession = useCallback(storage => {
    setStoredUser(storage);
    localStorage.setItem('user', JSON.stringify(storage));
  }, []);

  return (
    <SessionContext.Provider
      value={{
        storedUser,
        updateSession,
        sessionExpired: !storedUser
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
