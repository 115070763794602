import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://peaceful-bayou-94219.herokuapp.com'
    : 'http://localhost:8080';

const httpLink = createHttpLink({
  uri: `${API_URL}/query`
});

const authLink = setContext((_, { headers }) => {
  const storage = JSON.parse(localStorage.getItem('user'));
  const token = storage ? storage.authToken.accessToken : '';

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});
