import React, { useContext } from 'react';
import { BrowserRouter as Router, Link, Switch, Route } from 'react-router-dom';
import {
  AppProvider,
  TopBar,
  Navigation,
  Frame,
  Page,
  Layout,
  Card
} from '@shopify/polaris';
import {
  HomeMajorMonotone,
  ThumbsUpMajorMonotone,
  ReportsMajorMonotone,
  ArchiveMajorMonotone
} from '@shopify/polaris-icons';

import Login from '../login';
import UserProfile from '../user-profile';
import CreateProject from '../create-project';
import CreateTask from '../create-task';
import TaskManager from '../task-manager';
import BragFile from '../brag-file';
import Reports from '../reports';
import Archive from '../archive';

import { SessionContext } from '../../context/session-context';

export default function Main() {
  const { sessionExpired } = useContext(SessionContext);

  const topBarMarkup = <TopBar />;

  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            url: '/home',
            label: 'Home',
            icon: HomeMajorMonotone
          },
          {
            url: '/reports',
            label: 'Weekly Report',
            icon: ReportsMajorMonotone
          },
          {
            url: '/brag',
            label: 'Brag File',
            icon: ThumbsUpMajorMonotone
          },
          {
            url: '/archive',
            label: 'Archive',
            icon: ArchiveMajorMonotone
          }
        ]}
      />
    </Navigation>
  );

  const theme = {
    colors: {
      topBar: {
        background: '#6c5ce7'
      }
    }
    // logo: {
    //   width: 124,
    //   topBarSource: '',
    //   url: '',
    //   accessibilityLabel: 'Taskify'
    // }
  };

  const CustomLinkComponent = ({ children, url, ...rest }) => {
    return (
      <Link to={url} {...rest}>
        {children}
      </Link>
    );
  };

  if (sessionExpired) {
    return (
      <AppProvider i18n={{}}>
        <Page title="Taskify">
          <Login />
        </Page>
      </AppProvider>
    );
  }

  return (
    <Router>
      <AppProvider
        theme={theme}
        linkComponent={CustomLinkComponent}
        i18n={{
          Polaris: {
            Avatar: {
              label: 'Avatar',
              labelWithInitials: 'Avatar with initials {initials}'
            },
            Frame: { skipToContent: 'Skip to content' },
            TopBar: {
              toggleMenuLabel: 'Toggle menu',
              SearchField: {
                clearButtonLabel: 'Clear',
                search: 'Search'
              }
            }
          }
        }}
      >
        <Frame topBar={topBarMarkup} navigation={navigationMarkup}>
          <Page title="Taskify">
            <UserProfile />
            <Switch>
              <Route path="/home">
                <Layout>
                  <Layout.Section oneHalf>
                    <Card>
                      <Card.Section>
                        <CreateProject />
                      </Card.Section>
                    </Card>
                  </Layout.Section>
                  <Layout.Section oneHalf>
                    <Card>
                      <Card.Section>
                        <CreateTask />
                      </Card.Section>
                    </Card>
                  </Layout.Section>
                  <Layout.Section>
                    <TaskManager />
                  </Layout.Section>
                </Layout>
              </Route>
              <Route path="/reports">
                <Reports />
              </Route>
              <Route path="/brag">
                <BragFile />
              </Route>
              <Route path="/archive">
                <Archive />
              </Route>
            </Switch>
          </Page>
        </Frame>
      </AppProvider>
    </Router>
  );
}
