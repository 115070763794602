import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';

import { client } from './client';
import { SessionProvider } from './context/session-context';
import Main from './components/main/index';

function App() {
  return (
    <SessionProvider>
      <ApolloProvider client={client}>
        <Main />
      </ApolloProvider>
    </SessionProvider>
  );
}

export default App;
