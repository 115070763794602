import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Draggable } from 'react-beautiful-dnd';
import {
  Stack,
  ButtonGroup,
  Button,
  Modal,
  FormLayout,
  TextField
} from '@shopify/polaris';
import ReactMarkdown from 'react-markdown';

import Badge from '../../../common-components/badge';

const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $input: UpdateTask) {
    updateTask(id: $id, input: $input) {
      id
      name
      description
      brag
      state
    }
  }
`;

const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id)
  }
`;

export default function Task({ task, index }) {
  const [name, setName] = useState(task.name);
  const [description, setDescription] = useState(task.description);
  const [active, setActive] = useState(false);
  const [editing, setEditing] = useState(false);
  const [updateTask] = useMutation(UPDATE_TASK);
  const [deleteTask] = useMutation(DELETE_TASK);

  const handleNameChange = useCallback(value => setName(value), []);

  const handleDescriptionChange = useCallback(
    value => setDescription(value),
    []
  );

  const toggleEdit = useCallback(() => {
    setEditing(!editing);
  }, [editing]);

  const handleChange = useCallback(() => {
    setActive(!active);
  }, [active]);

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    background: isDragging ? '#f5f6fa' : 'white',
    border: isDragging ? '2px dashed #a49beb' : '',

    ...draggableStyle
  });

  const handleToggleBrag = useCallback(
    task => {
      updateTask({
        variables: {
          id: task.id,
          input: {
            brag: !task.brag
          }
        },
        refetchQueries: ['GetTasks']
      });
    },
    [updateTask]
  );

  const handleUpdateTask = useCallback(
    task => {
      updateTask({
        variables: {
          id: task.id,
          input: {
            name: name,
            description: description
          }
        },
        refetchQueries: ['GetTasks']
      });
      toggleEdit();
    },
    [updateTask, name, description, toggleEdit]
  );

  const handleCompleteTask = useCallback(
    task => {
      updateTask({
        variables: {
          id: task.id,
          input: {
            completed: true
          }
        },
        refetchQueries: ['GetTasks']
      });
    },
    [updateTask]
  );

  const handleDeleteTask = useCallback(
    id => {
      deleteTask({
        variables: {
          id
        },
        refetchQueries: ['GetTasks']
      });
      handleChange();
    },
    [deleteTask, handleChange]
  );

  return (
    <div>
      <Draggable draggableId={task.id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
            onClick={handleChange}
          >
            <Stack>
              <Badge color={task.project.labelColor} />
              <p>{task.name}</p>
            </Stack>
          </div>
        )}
      </Draggable>
      <Modal open={active} onClose={handleChange} title={task.name}>
        <Modal.Section>
          <Badge text={task.project.name} color={task.project.labelColor} />
        </Modal.Section>
        {editing && (
          <Modal.Section>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  value={name}
                  type="text"
                  label="Name"
                  onChange={handleNameChange}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  value={description}
                  label="Description"
                  onChange={handleDescriptionChange}
                  multiline
                />
              </FormLayout.Group>
            </FormLayout>
            <Modal.Section>
              <Stack distribution="trailing">
                <ButtonGroup>
                  <Button onClick={() => toggleEdit(task.id)}>
                    {' '}
                    <span role="img" aria-label="red-x">
                      ❌
                    </span>
                  </Button>
                  <Button onClick={() => handleUpdateTask(task)}>
                    {' '}
                    <span role="img" aria-label="red-x">
                      💾
                    </span>
                  </Button>
                </ButtonGroup>
              </Stack>
            </Modal.Section>
          </Modal.Section>
        )}
        {!editing && (
          <Modal.Section>
            <Modal.Section>
              <ReactMarkdown source={task.description} />
            </Modal.Section>
            <Modal.Section>
              <Stack distribution="trailing">
                <ButtonGroup>
                  <Button onClick={() => handleDeleteTask(task.id)}>
                    {' '}
                    <span role="img" aria-label="red-x">
                      ❌
                    </span>
                  </Button>
                  <Button onClick={() => handleToggleBrag(task)}>
                    {' '}
                    <span role="img" aria-label="brag">
                      {task.brag ? '🤦‍♂️' : '🎉'}
                    </span>
                  </Button>
                  <Button onClick={() => toggleEdit(task.id)}>
                    {' '}
                    <span role="img" aria-label="pencil">
                      ✏️
                    </span>
                  </Button>
                  <Button>
                    {' '}
                    <span
                      role="img"
                      aria-label="green-checkmark"
                      onClick={() => handleCompleteTask(task)}
                    >
                      ✅
                    </span>
                  </Button>
                </ButtonGroup>
              </Stack>
            </Modal.Section>
          </Modal.Section>
        )}
      </Modal>
    </div>
  );
}
