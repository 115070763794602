import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { FormLayout, TextField, Button } from '@shopify/polaris';
import { TwitterPicker } from 'react-color';

import Badge from '../../common-components/badge';

const CREATE_PROJECT = gql`
  mutation CreateProject($input: NewProject!) {
    createProject(input: $input) {
      id
      createdAt
      updatedAt
      name
      labelColor
    }
  }
`;

export default function CreateProject() {
  const [name, setName] = useState('');
  const [labelColor, setLabelColor] = useState('');
  const [createProject] = useMutation(CREATE_PROJECT);

  const handleNameChange = useCallback(value => setName(value), []);
  const handleLabelColorChange = useCallback(
    color => setLabelColor(color.hex),
    []
  );
  const handleSubmit = useCallback(() => {
    createProject({
      variables: {
        input: {
          name,
          labelColor
        }
      },
      refetchQueries: ['GetProjects']
    });

    setName('');
    setLabelColor('');
  }, [createProject, name, labelColor]);

  return (
    <FormLayout>
      <FormLayout.Group>
        <TextField
          value={name}
          type="text"
          label="Name"
          onChange={handleNameChange}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Badge text={name !== '' ? name : 'Project Name'} color={labelColor} />
        <TwitterPicker onChange={handleLabelColorChange} />
      </FormLayout.Group>
      <Button primary submit onClick={handleSubmit}>
        Create
      </Button>
    </FormLayout>
  );
}
