import React, { useState, useCallback, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  Card,
  Button,
  Form as PolarisForm,
  FormLayout,
  TextField
} from '@shopify/polaris';

import { SessionContext } from '../../context/session-context';

import logo from '../../assets/taskify.svg';

import './styles.scss';

const LOGIN = gql`
  mutation LoginUser($input: LoginInput!) {
    login(input: $input) {
      authToken {
        accessToken
      }

      user {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

function Login() {
  const { updateSession } = useContext(SessionContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = useCallback(newEmail => setEmail(newEmail), []);
  const handlePasswordChange = useCallback(
    newPassword => setPassword(newPassword),
    []
  );

  const [login] = useMutation(LOGIN, {
    onCompleted({ login }) {
      updateSession({
        ...login.user,
        authToken: login.authToken
      });
      setEmail('');
      setPassword('');
    }
  });

  const submit = () => {
    login({
      variables: { input: { email, password } }
    });
  };

  return (
    <Card sectioned>
      <PolarisForm>
        <FormLayout>
          <div className="Logo">
            <img src={logo} alt="Taskify logo" />
          </div>
          <TextField
            value={email}
            type="email"
            label="Account email"
            onChange={handleEmailChange}
          />
          <TextField
            value={password}
            type="password"
            label="Password"
            onChange={handlePasswordChange}
          />
          <Button onClick={() => submit()}>Login</Button>
        </FormLayout>
      </PolarisForm>
    </Card>
  );
}

export default Login;
