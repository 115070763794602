import React, { useState, useCallback, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  FormLayout,
  TextField,
  Button,
  Spinner,
  InlineError,
  Select
} from '@shopify/polaris';

const CREATE_TASK = gql`
  mutation CreateTask($input: NewTask!) {
    createTask(input: $input) {
      id
      name
      description
      brag
      state
    }
  }
`;

const PROJECTS = gql`
  query GetProjects {
    projects {
      id
      name
      labelColor
    }
  }
`;

export default function CreateTask() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [projectId, setProjectId] = useState('');
  const [projects, setProjects] = useState([]);
  const { loading, error, data } = useQuery(PROJECTS);
  const [createTask] = useMutation(CREATE_TASK);

  useEffect(() => {
    if (!error && !loading) {
      setProjects(data.projects);

      if (data.projects.length > 0) {
        setProjectId(data.projects[0].id);
      }
    }
  }, [loading, error, data]);

  const handleNameChange = useCallback(value => setName(value), []);
  const handleDescriptionChange = useCallback(
    value => setDescription(value),
    []
  );
  const handleSelectChange = useCallback(value => setProjectId(value), []);

  const handleSubmit = useCallback(() => {
    createTask({
      variables: {
        input: {
          name,
          description,
          projectId
        }
      },
      refetchQueries: ['GetTasks']
    });

    setName('');
    setDescription('');
  }, [createTask, name, description, projectId]);

  if (loading || error) {
    return (
      <FormLayout>
        {loading && (
          <Spinner
            accessibilityLabel="Spinner example"
            size="large"
            color="teal"
          />
        )}
        {error && <InlineError message="Error" fieldID="error" />}
      </FormLayout>
    );
  }

  const options = projects.map(project => {
    return { label: project.name, value: project.id };
  });

  return (
    <FormLayout>
      <FormLayout.Group>
        <TextField
          value={name}
          type="text"
          label="Name"
          onChange={handleNameChange}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField
          value={description}
          label="Description"
          onChange={handleDescriptionChange}
          multiline
        />
      </FormLayout.Group>
      <Select
        label="Project"
        options={options}
        onChange={handleSelectChange}
        value={projectId}
      />
      <Button primary submit onClick={handleSubmit}>
        Create
      </Button>
    </FormLayout>
  );
}
