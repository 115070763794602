import React, { useContext } from 'react';
import { DisplayText } from '@shopify/polaris';

import { SessionContext } from '../../context/session-context';

function UserProfile() {
  const { storedUser } = useContext(SessionContext);

  return (
    <div>
      <DisplayText size="small">{`Welcome back, ${storedUser.first_name}.`}</DisplayText>
    </div>
  );
}

export default UserProfile;
