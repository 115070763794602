import React from 'react';

import './styles.scss';

export default function Badge({ text, color }) {
  return (
    <div className="badgeBox">
      <span style={{ backgroundColor: color || '#ABB8C2' }}>{text}</span>
    </div>
  );
}
