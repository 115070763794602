import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Layout, Card, OptionList, Button, Stack } from '@shopify/polaris';
import moment from 'moment';

const TASKS = gql`
  query GetTasks($limit: Int, $filter: TaskFilter) {
    todo: tasks(limit: $limit, filter: { completed: false, state: ACTIVE }) {
      id
      name
      description
      brag
      state
      project {
        id
        name
        labelColor
      }
    }
    completed: tasks(limit: $limit, filter: $filter) {
      id
      name
      description
      brag
      state
      project {
        id
        name
        labelColor
      }
    }
  }
`;

export default function Reports() {
  const start = moment()
    .startOf('week')
    .format();

  const end = moment()
    .endOf('week')
    .format();

  const [selectedTodo, setSelectedTodo] = useState([]);
  const [selectedCompleted, setSelectedCompleted] = useState([]);
  const [copyTimeout, setCopyTimeout] = useState(false);

  const { loading, error, data } = useQuery(TASKS, {
    variables: {
      limit: 250,
      filter: {
        completed: true,
        dateRange: {
          field: 'created_at',
          start: start,
          end: end
        }
      }
    }
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const { todo, completed } = data;

  const todoOptions = todo.map(t => ({ value: t.id, label: t.name }));
  const compltedOptions = completed.map(t => ({ value: t.id, label: t.name }));

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text);
  };

  const generateReport = () => {
    setCopyTimeout(true);
    setTimeout(() => {
      setCopyTimeout(false);
    }, 1000);

    const completed = compltedOptions.filter(option =>
      selectedCompleted.includes(option.value)
    );
    const todo = todoOptions.filter(option =>
      selectedTodo.includes(option.value)
    );

    const clipboardCompleted = completed.map(task => {
      return `- ${task.label}\n`;
    });

    const clipboardTodo = todo.map(task => {
      return `- ${task.label}\n`;
    });

    copyToClipboard(
      `Completed ✅: \n${clipboardCompleted.join(
        ''
      )}\n\nTodo 📝: \n${clipboardTodo.join('')}\n\n`
    );
  };

  const reset = () => {
    setSelectedTodo([]);
    setSelectedCompleted([]);
  };

  return (
    <Layout>
      <Layout.Section oneHalf>
        <Card>
          <OptionList
            title="To do next week 📝"
            onChange={setSelectedTodo}
            options={todoOptions}
            selected={selectedTodo}
            allowMultiple
          />
        </Card>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Card>
          <OptionList
            title="Completed this week ✅"
            onChange={setSelectedCompleted}
            options={compltedOptions}
            selected={selectedCompleted}
            allowMultiple
          />
        </Card>
      </Layout.Section>
      <Layout.Section>
        <Stack distribution="trailing">
          <div style={{ color: '#bf0711' }}>
            <Button onClick={() => reset()} monochrome outline>
              Reset
            </Button>
          </div>
          <Button onClick={() => generateReport()}>
            {copyTimeout ? 'Copied ✅' : 'Generate'}
          </Button>
        </Stack>
      </Layout.Section>
    </Layout>
  );
}
