import React, { useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Layout, Card, Link, Page, DataTable, Stack } from '@shopify/polaris';
import ReactMarkdown from 'react-markdown';

const TASKS = gql`
  query GetTasks {
    tasks(limit: 250) {
      id
      name
      description
      brag
      state
      completed
      project {
        id
        name
        labelColor
      }
    }
  }
`;

const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $input: UpdateTask) {
    updateTask(id: $id, input: $input) {
      id
      name
      description
      brag
      state
    }
  }
`;

export default function Archive() {
  const rows = [];
  const { loading, error, data } = useQuery(TASKS);
  const [updateTask] = useMutation(UPDATE_TASK);

  const handleToggleCompleted = useCallback(
    task => {
      updateTask({
        variables: {
          id: task.id,
          input: {
            completed: !task.completed
          }
        },
        refetchQueries: ['GetTasks']
      });
    },
    [updateTask]
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  data.tasks.forEach(task => {
    const row = [
      task.name,
      <ReactMarkdown>{task.description}</ReactMarkdown>,
      <Stack>
        {task.completed && (
          <Link onClick={() => handleToggleCompleted(task)}>Activate</Link>
        )}
      </Stack>
    ];
    rows.push(row);
  });

  return (
    <Layout>
      <Page title="Archived Task">
        <Card>
          <DataTable
            columnContentTypes={['text', 'text', 'text']}
            headings={['Name', 'Description', 'Action']}
            rows={rows}
          />
        </Card>
      </Page>
    </Layout>
  );
}
