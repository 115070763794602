import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Layout, Card } from '@shopify/polaris';

import Task from './components/Task';

const TASKS = gql`
  query GetTasks {
    tasks(limit: 250, filter: { completed: false }) {
      id
      name
      description
      brag
      state
      project {
        id
        name
        labelColor
      }
    }
  }
`;

const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $input: UpdateTask) {
    updateTask(id: $id, input: $input) {
      id
      name
      description
      brag
      state
    }
  }
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result.droppable = sourceClone;
  result.droppable2 = destClone;

  return result;
};

const grid = 8;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#a49beb' : '#E6ECF0',
  padding: grid,
  width: 250
});

export default function TaskManager() {
  const [activeList, setActiveList] = useState([]);
  const [queueList, setQueueList] = useState([]);
  const [backlogList, setBacklogList] = useState([]);
  const [updateTask] = useMutation(UPDATE_TASK);

  const { loading, error, data } = useQuery(TASKS);

  useEffect(() => {
    if (!error && !loading) {
      const active = [];
      const queue = [];
      const backlog = [];

      data.tasks.forEach(task => {
        switch (task.state) {
          case 'ACTIVE':
            active.push(task);
            break;
          case 'QUEUE':
            queue.push(task);
            break;
          case 'BACKLOG':
            backlog.push(task);
            break;
          default:
            break;
        }
      });

      setActiveList(active);
      setQueueList(queue);
      setBacklogList(backlog);
    }
  }, [loading, error, data]);

  const getList = droppableId => {
    switch (droppableId) {
      case 'droppableActive':
        return activeList;
      case 'droppableQueue':
        return queueList;
      case 'droppableBacklog':
        return backlogList;
      default:
        return;
    }
  };

  const setList = (droppableId, list) => {
    switch (droppableId) {
      case 'droppableActive':
        setActiveList(list);
        break;
      case 'droppableQueue':
        setQueueList(list);
        break;
      case 'droppableBacklog':
        setBacklogList(list);
        break;
      default:
        return;
    }
  };

  const getState = droppableId => {
    switch (droppableId) {
      case 'droppableActive':
        return 'ACTIVE';
      case 'droppableQueue':
        return 'QUEUE';
      case 'droppableBacklog':
        return 'BACKLOG';
      default:
        return;
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const tasks = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === 'droppableActive') {
        setActiveList(tasks);
      }

      if (source.droppableId === 'droppableQueue') {
        setQueueList(tasks);
      }

      if (source.droppableId === 'droppableBacklog') {
        setBacklogList(tasks);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      const updatedTask = getList(source.droppableId)[source.index];

      updateTask({
        variables: {
          id: updatedTask.id,
          input: {
            state: getState(destination.droppableId)
          }
        },
        refetchQueries: ['GetTasks']
      });

      setList(source.droppableId, result.droppable);
      setList(destination.droppableId, result.droppable2);
    }
  };

  return (
    <Layout>
      <DragDropContext onDragEnd={onDragEnd}>
        <Layout.Section oneThird>
          <Card title="Backlog">
            <Card.Section title="Stuff I'll get to">
              <Droppable droppableId="droppableBacklog">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {backlogList.map((task, index) => (
                      <Task key={task.id} task={task} index={index} />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section oneThird>
          <Card title="Queue">
            <Card.Section title="Tasks lined up for this week">
              <Droppable droppableId="droppableQueue">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {queueList.map((task, index) => (
                      <Task key={task.id} task={task} index={index} />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section oneThird>
          <Card title="Active">
            <Card.Section title="Tasks to conquer today ⚔️🛡️">
              <Droppable droppableId="droppableActive">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {activeList.map((task, index) => (
                      <Task key={task.id} task={task} index={index} />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Card.Section>
          </Card>
        </Layout.Section>
      </DragDropContext>
    </Layout>
  );
}
