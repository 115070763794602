import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Layout, Card, List } from '@shopify/polaris';

const PROJECTS = gql`
  query GetBragProjects {
    projects(limit: 250) {
      id
      createdAt
      updatedAt
      name
      labelColor
      tasks(filter: { bragFile: true }) {
        id
        name
      }
    }
  }
`;

export default function BragFile() {
  const { loading, error, data } = useQuery(PROJECTS, {
    fetchPolicy: 'network-only'
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const { projects } = data;

  return (
    <Layout>
      <Layout.Section oneHalf>
        {projects.map(project => {
          if (project.tasks.length !== 0) {
            return (
              <Card key={project.id} title={project.name}>
                <Card.Section title="Bragged tasks">
                  <List>
                    {project.tasks.map(task => (
                      <List.Item key={task.id}>{task.name}</List.Item>
                    ))}
                  </List>
                </Card.Section>
              </Card>
            );
          }
          return <></>;
        })}
      </Layout.Section>
    </Layout>
  );
}
